.container.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 375px;
  padding: 35px 35px 0;
}

.outerContainer {
  flex-grow: 1;
}

.title {
  margin: 0 0 30px;
  font-size: 18px;
}
